<template>
  <v-dialog persistent @keydown.esc="close" v-model="bDialogActionConfirm" :width="screenWidth + '%'">
    <v-card class="global-container-dialog">
      <v-btn @click="close" icon class="global-btn-close">
        <v-icon> mdi-close </v-icon>
      </v-btn>

      <v-card-title>
        <div class="content-title-delete">
          <p class="title-delete">
            {{ itemDialog.textTitleActionConfirm }}
          </p>
        </div>
      </v-card-title>

      <v-card-text>
        <div class="content-description-delete">
          <p class="description-delete" v-html="itemDialog.textDescriptionActionConfirm" />
        </div>
        <div class="content-question-delete">
          <v-checkbox class="global-checkbox" color="var(--primary-color-text-yellow)" v-model="checkbox">
            <template v-slot:label>
              <div>
                <p class="question-delete">
                  {{ itemDialog.textQuestionActionConfirm }}
                </p>
              </div>
            </template>
          </v-checkbox>
        </div>
      </v-card-text>

      <v-card-text>
        <div class="content-btns-accions">
          <div class="content-btn-second">
            <v-btn @click="close" class="global-btn-neutral"> Cancelar </v-btn>
          </div>

          <v-spacer></v-spacer>
          <div class="content-btn-primary">
            <v-btn :disabled="!checkbox" :loading="bLoading" class="global-btn-primary" @click="executeAction()">
              Aceptar
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    bDialogActionConfirm: Boolean,
    oDialogConfirm: Object,
  },
  data() {
    return {
      screenWidth: 0,
      checkbox: false,
      returnToView: false,
      bAdminGlobal: false,
      bLoading: false,
      itemDialog: {},
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      // size to range
      if (window.innerWidth < 599) {
        this.screenWidth = 100;
      } else if (window.innerWidth > 600 && window.innerWidth < 959) {
        this.screenWidth = 70;
      } else if (window.innerWidth > 960 && window.innerWidth < 1263) {
        this.screenWidth = 50;
      } else if (window.innerWidth > 1264 && window.innerWidth < 1903) {
        this.screenWidth = 50;
      } else if (window.innerWidth > 1904) {
        this.screenWidth = 30;
      }
    },
    executeAction() {
      this.bLoading = true;

      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          switch (this.$route.name) {
            case "Provider":
              this.bAdminGlobal = this.bAdminProvider;
              break;
            case "ProviderDetail":
              this.bAdminGlobal = this.bAdminProvider;
              break;
            case "RawMaterial":
              this.bAdminGlobal = this.bAdminMaterial;

              break;
            case "Administrators":
              this.bAdminGlobal = this.bAdminAdministrator;

              break;
            case "AdministratorsDetail":
              this.bAdminGlobal = this.bAdminAdministrator;

              break;
            case "Account":
              this.bAdminGlobal = this.bAdminAccount;

              break;
            case "AccountDetail":
              this.bAdminGlobal = this.bAdminAccount;

              break;
            case "OPGeneralDetail":
              this.bAdminGlobal = this.bAdminOPGeneral;

              break;
            case "OPPendingDetail":
              this.bAdminGlobal = this.bAdminOPPending;

              break;
            case "Customer":
              this.bAdminGlobal = this.bAdminCustomer;

              break;
            case "CustomerDetail":
              this.bAdminGlobal = this.bAdminCustomer;

              break;
            default:
              break;
          }
          if (this.bAdminGlobal) {
            const payload = this.itemDialog.oParams,
              config = {
                headers: {
                  Authorization: "Bearer " + this.$store.state.sToken,
                },
              };
            switch (this.itemDialog.iTypeAction) {
              case 1: //1= Post
                DB.post(this.itemDialog.apiActionConfirm, payload, config)
                  .then((response) => {
                    this.bLoading = false;
                    this.close();
                    this.Success(response.data.message);
                    if (this.itemDialog.returnToViewActionConfirm) {
                      this.$router.go(-1);
                    } else {
                      this.$store.commit("refresher", true);
                    }
                  })
                  .catch((error) => {
                    this.bLoading = false;
                    this.Error(error.response.data);
                  });

                break;
              case 2: // 2= put
                break;
              case 3: //3= patch
                DB.patch(this.itemDialog.apiActionConfirm, payload,config)
                  .then((response) => {
                    this.bLoading = false;
                    this.close();
                    this.$store.commit("setEmptyRawMaterials")

                    this.Success(response.data.message);
                    if (this.itemDialog.returnToViewActionConfirm) {
                      this.$router.go(-1);
                    } else {
                      this.$store.commit("refresher", true);
                    }
                  })
                  .catch((error) => {

                    this.bLoading = false;
                    this.Error(error.response.data);
                  });
                break;
              case 4: // 4=delete
                DB.delete(this.itemDialog.apiActionConfirm, config, payload)
                  .then((response) => {
                    this.bLoading = false;
                    this.close();
                    this.Success(response.data.message);
                    if (this.itemDialog.returnToViewActionConfirm) {
                      this.$router.go(-1);
                    } else {
                      this.$store.commit("refresher", true);
                    }
                  })
                  .catch((error) => {

                    this.bLoading = false;
                    this.Error(error.response.data);
                  });

                break;
              case 5: // 5 = get
                break;
              case 6: // 6 = purge
                DB.purge(this.itemDialog.apiActionConfirm, config, payload)
                  .then((response) => {
                    this.bLoading = false;
                    this.close();
                    this.Success(response.data.message);
                    if (this.itemDialog.returnToViewActionConfirm) {
                      this.$router.go(-1);
                    } else {
                      this.$store.commit("refresher", true);
                    }
                  })
                  .catch((error) => {

                    this.bLoading = false;
                    this.Error(error.response.data);
                  });
                break;

              default:
                break;
            }
          } else {
            this.Error(this.$store.state.oError403);
            this.close();
          }
        })
        .catch((err) => {
          this.Error(err);
        });
    },

    close() {
      this.$emit("setDialogActionConfirmGlobal");
      this.checkbox = false;
      this.bLoading = false;
    },
  },
  computed: {
    //PERMISSIONS PROVIDER
    bAdminProvider() {
      return this.$store.state.bAdminProvider;
    },
    bShowProvider() {
      return this.$store.state.bShowProvider;
    },
    //PERMISSIONS MATERIAL
    bAdminMaterial() {
      return this.$store.state.bAdminMaterial;
    },
    bShowMaterial() {
      return this.$store.state.bShowMaterial;
    },
    //PERMISSIONS ADMIN
    bAdminAdministrator() {
      return this.$store.state.bAdminAdministrator;
    },
    bShowAdministrator() {
      return this.$store.state.bShowAdministrator;
    },
    //PERMISSIONS CUSTOMER
    bAdminCustomer() {
      return this.$store.state.bAdminCustomer;
    },
    bShowCustomer() {
      return this.$store.state.bShowCustomer;
    },
    //PERMISSIONS PURCHASE ORDERS
    bAdminOPGeneral() {
      return this.$store.state.bAdminOPGeneral;
    },
    bShowOPGeneral() {
      return this.$store.state.bShowOPGeneral;
    },
    //PERMISSIONS PURCHASE ORDERS PENDING
    bAdminOPPending() {
      return this.$store.state.bAdminOPPending;
    },
    bShowOPPending() {
      return this.$store.state.bShowOPPending;
    },
    //PERMISSIONS CUSTOMER
    bAdminCustomer() {
      return this.$store.state.bAdminCustomer;
    },
    bShowCustomer() {
      return this.$store.state.bShowCustomer;
    },
  },
  watch: {
    bDialogActionConfirm() {
      if (this.bDialogActionConfirm) {
        this.itemDialog = this.oDialogConfirm;
      }
    },
  },
};
</script>
<style scoped>
.content-title-delete {
  width: 100%;
}

.content-description-delete {
  width: 100%;
}

.title-delete {
  color: var(--primary-color-text) !important;
  font-family: "pop-SemiBold";
  font-size: 25px;
  text-align: center;
}

.description-delete {
  color: var(--primary-color-text) !important;
  font-family: "pop-Regular";
  font-size: 14px;
  text-align: center;
}

.content-question-delete {
  width: 100%;
  justify-self: center;
  display: flex;
  justify-content: center;
  padding: 10px 45px 10px 45px;
}

.question-delete {
  color: var(--primary-color-text) !important;
  margin-bottom: 0px;
  font-family: "pop-Regular";
  font-size: 12px;
  text-align: start;
  letter-spacing: 0px;
}

.content-btns-accions {
  display: flex;
  width: 100% !important;
}

.content-btn-primary {
  width: 100%;
}

.content-btn-second {
  width: 100%;
  margin-right: 15px;
}

/**************************MODO RESPONSIVO ********************/
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-btns-accions {
    display: block;
    width: 100% !important;
  }

  .content-btn-second {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }

  .content-question-delete {
    width: 100%;
    justify-self: center;
    display: flex;
    justify-content: center;
    padding: 10px 10px 10px 10px !important;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>